<template>
  <div class="pa-5">
    <!-- <v-card> -->
    <v-toolbar>
      <v-toolbar-title>Тусламж</v-toolbar-title>
      <v-divider
        class="mx-4"
        inset
        vertical
      ></v-divider>
      <v-col
        cols="12"
        sm="5"
      >
        <v-combobox
          v-model="selectOrgs"
          hint="Видео хичээлийн заавар"
          persistent-hint
          hide-details
          :items="orgs"
          item-text="text"
          item-value="value"
          item-src="src"
          outlined
          dense
          @change="selectOrg"
        ></v-combobox>
      </v-col>
      <v-col
        cols="12"
        sm="5"
      >
        <v-btn
          color="blue lighten-2"
          dark
          class="ma-2"
          download
          href="journal_template.xlsx"
        >
          Импортийн загвар файл татах <i class="mdi mdi-download" aria-hidden="true"></i>
        </v-btn>
      </v-col>
    </v-toolbar>

    <v-col
      class="pa-8"
      cols="auto"
      sm="6"
    >
      <iframe id="fbif" :src="selectOrgs.src" width="560" height="314" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
    </v-col>
    <!-- </v-card> -->
  </div>
</template>

<script>
  export default {
    data (){
      return {
        selectOrgs: { text: '01. Програмд шинэ компани нэмэх', value: '1', src: 'https://www.facebook.com/plugins/video.php?height=316&href=https%3A%2F%2Fwww.facebook.com%2Fexcelfinance.mn%2Fvideos%2F1504454269921823%2F&show_text=false&width=560&t=0' },
        orgs: [
          { text: '01. Програмд шинэ компани нэмэх', value: '1', src: 'https://www.facebook.com/plugins/video.php?height=316&href=https%3A%2F%2Fwww.facebook.com%2Fexcelfinance.mn%2Fvideos%2F1504454269921823%2F&show_text=false&width=560&t=0' },
          { text: '02. Данс нэмэх, дансы код засах, эхний үлдэгдэл оруулах', value: '2', src: 'https://www.facebook.com/plugins/video.php?height=316&href=https%3A%2F%2Fwww.facebook.com%2Fexcelfinance.mn%2Fvideos%2F224622379636787%2F&show_text=false&width=560&t=0' },
          { text: '03. Гүйлгээ шивэх', value: '3', src: 'https://www.facebook.com/plugins/video.php?height=316&href=https%3A%2F%2Fwww.facebook.com%2Fexcelfinance.mn%2Fvideos%2F213170064135015%2F&show_text=false&width=560&t=0' },
          { text: '04. Дансны хуулга болон авлага, өглөгийн хуулга авах', value: '4', src: 'https://www.facebook.com/plugins/video.php?height=316&href=https%3A%2F%2Fwww.facebook.com%2Fexcelfinance.mn%2Fvideos%2F545273846555180%2F&show_text=false&width=560&t=0' },
          { text: '05. Санхүүгийн онлайн програмд цалин бүртгэх', value: '5', src: 'https://www.facebook.com/plugins/video.php?height=316&href=https%3A%2F%2Fwww.facebook.com%2Fexcelfinance.mn%2Fvideos%2F1100922907380304%2F&show_text=false&width=560&t=0' },
          { text: '06. Програмд эксел файлаас олон гүйлгээ импортоор оруулах', value: '6', src: 'https://www.facebook.com/plugins/video.php?height=316&href=https%3A%2F%2Fwww.facebook.com%2Fexcelfinance.mn%2Fvideos%2F2000200593475098%2F&show_text=false&width=560&t=0' },
          { text: '07. Өмнөх оны эцсийн үлдэгдэлээ дараа онруу автоматаар хуулах', value: '7', src: 'https://www.facebook.com/plugins/video.php?height=316&href=https%3A%2F%2Fwww.facebook.com%2Fexcelfinance.mn%2Fvideos%2F635621380868074%2F&show_text=false&width=560&t=0' },
        ],
      }
    },
    computed: {
    },
    methods: {
      selectOrg (item) {
        this.selectOrgs = item
        document.getElementById('fbif').src = item.src
      }
    }
  }
</script>