<template>
   <v-app id="inspire" align='center'>
      <v-main>
         <v-container fluid fill-height>
            <v-layout align-center justify-center>
               <v-flex xs12 sm8 md4>
                  <v-card class="elevation-12">
                     <v-toolbar dark color="primary">
                        <v-toolbar-title>Шинээр хэрэглэгч бүртгэх хэсэг</v-toolbar-title>
                     </v-toolbar>
                     <v-card-text>
                        <v-form ref="form">
                           <v-text-field
                              prepend-icon="mdi-account"
                              v-model="lastname"
                              label="Овог *"
                              :rules="[required]"
                              type="text"
                           ></v-text-field>
                           <v-text-field
                              prepend-icon="mdi-account"
                              v-model="firstname"
                              label="Нэр *"
                              :rules="[required]"
                              type="text"
                           ></v-text-field>
                           <v-text-field
                              prepend-icon="mdi-phone"
                              v-model="phone"
                              label="Утасны дугаар *"
                              :rules="[required]"
                              type="text"
                           ></v-text-field>
                           <v-text-field
                              prepend-icon="mdi-email"
                              v-model="email"
                              name="email *"
                              label="Email хаяг"
                              :rules="[required]"
                              type="text"
                           ></v-text-field>
                           <v-text-field
                              prepend-icon="mdi-lock"
                              v-model="password"
                              label="Нууц үг *"
                              :rules="[required]"
                              type="password"
                           ></v-text-field>
                           <v-text-field
                              prepend-icon="mdi-lock"
                              v-model="password_confirm"
                              label="Нууц үг дахин оруулах *"
                              :rules="[required]"
                              type="password"
                           ></v-text-field>
                        </v-form>
                     </v-card-text>
                     <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn :loading="loading" color="primary lighten-2" @click="register">Хүсэлт илгээх</v-btn>
                     </v-card-actions>
                  </v-card>

                  <br/>
                  <div>
                        Бүртгэгдсэн хэрэглэгч үү?
                        <v-btn color="green lighten-2" light to='/login'>Нэвтрэх</v-btn>
                  </div>

               </v-flex>
            </v-layout>
         </v-container>
      </v-main>
      <v-footer id='app'>
         <v-flex class='text-xs-center'>© 2021. All rights reserved. Excel Finance</v-flex>
      </v-footer>
   </v-app>
</template>

<script>
export default {
   data(){
      return {
            lastname: '',
            firstname: '',
            phone: '',
            email: '',
            password: '',
            password_confirm: '',
            is_admin: null,
            loading: false,
      }
   },
   methods: {
      required(value) {
         if (value instanceof Array && value.length == 0) {
            return 'Заавал утга оруулна уу.';
         }
         return !!value || 'Заавал утга оруулна уу.';
      },
      
      register: function () {
         if (!this.$refs.form.validate()) {
          return;
         }
         this.loading = true;
         let data = {
            firstname: this.firstname,
            lastname: this.lastname,
            phone: this.phone,
            email: this.email,
            password: this.password,
            password_confirm: this.password_confirm,
            is_admin: this.is_admin
         }
         this.$store.dispatch('register', data)
            .then(() => {
               alert('Мэдээлэл амжилттай бүртгэж авлаа.');
               this.loading = false;
               this.$router.push('/');
            })
            .catch(err => {
               alert('Мэдээлэл бүртгэх үед алдаа гарлаа.');
               this.loading = false;
               console.log(err);
            })
      }
   }
}
</script>