<template>
<div style="background-color:#e4e4e4" class="pa-5">
  <v-data-table
    v-model="selected"
    :headers="headers"
    :items="filteredGridRows"
    :search="search"
    class="elevation-1"
    dense
    item-key="id"
    show-select
    calculate-widths
    fixed-header
    height="60vh"
    :loading="loadTable"
    loading-text="Ачааллаж байна... Түр хүлээнэ үү!"
    no-data-text="Мэдээлэл байхгүй байна."
    no-results-text="Тохирох үр дүн олдсонгүй. Та дахин хайна уу?"
  >
    <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-toolbar-title>Харилцагч байгууллага, хувь хүний жагсаалт</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <v-col cols="12" sm="3">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Хайлт"
            rounded
            filled
            background-color="blue lighten-5"
            clearable
            hide-details
          ></v-text-field>
        </v-col>

        <v-dialog
          persistent
          no-click-animation
          v-model="dialogUpdateAmnt"
          width="650"
        >
          <template v-slot:activator="{ click }">
          <v-btn
            slot="activator"
            color="blue lighten-2"
            dark
            class="mb-2 mr-2"
            v-if="!readonly_check"
            @click="updateAmnt"
          >
            Э/Ү шинэчлэх
          </v-btn>
          </template>
        
          <v-card>
            <v-card-title
              class="headline blue lighten-2"
              primary-title
            >
              Тухайн оны дүнгээр эхний үлдэгдэл шинэчлэх үү?
            </v-card-title>
    
            <v-card-text>
              <br>Тухайн оны харилцагчийн үлдэгдэл дараа оны эхний үлдэгдэл болон хуулагдах болно.<br>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-text-field
                  v-model="amntYear1"
                  label="Дансны үлдэгдэлийн харгалзах он"
                  :rules="[required]"
                  outlined
                  readonly
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-text-field
                  v-model="amntYear2"
                  label="Эхний үлдэгдэл болон хуулагдах он"
                  :rules="[required]"
                  outlined
                  readonly
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <br>Дээрх утгыг шалгана уу.
              
            </v-card-text>
    
            <v-divider></v-divider>
    
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary darken-1"
                text
                @click="closeUpdateAmnt"
              >
                Үгүй
              </v-btn>
              <v-btn
                color="primary darken-1"
                text
                @click="updateAmntConfirm"
              >
                Тийм
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog
          v-model="dialog"
          max-width="500px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="green lighten-2"
              dark
              class="mb-2"
              v-if="!readonly_check"
              v-bind="attrs"
              v-on="on"
            >
              Нэмэх
              <v-icon small class="ml-1">mdi-plus-thick</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-title class="headline green lighten-2" primary-title>
              {{ formTitle }}
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-form ref="form">
                <v-row>
                  <v-col
                    cols="12"
                    sm="8"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.reg_id"
                      label="Регистр"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.name"
                      label="Нэр *"
                      :rules="[required]"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.phone"
                      label="Утас"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.bank_acct"
                      label="Банкны данс"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.amnt"
                      label="Эхний үлдэгдэл"
                      type="number"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <h4 style="color:red">* Заавал бөглөх талбар</h4>
                </v-row>
                </v-form>
              </v-container>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="close"
              >
                Цуцлах
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="save"
              >
                Хадгалах
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog
          persistent
          no-click-animation
          v-model="dialogDelete"
          width="500"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              slot="activator"
              color="red lighten-2"
              dark
              class="mb-2 ml-2"
              v-if="!readonly_check"
              @click="deleteItem"
            >
              Устгах
              <v-icon small class="ml-1">mdi-delete</v-icon>
            </v-btn>
          </template>
    
          <v-card>
            <v-card-title
              class="headline red lighten-2"
              primary-title
            >
              Мэдээллийг устгах уу?
            </v-card-title>
    
            <v-card-text>
              Сонгогдсон харилцагч байгууллага, хувь хүний мэдээллийг устгахад итгэлтэй байна уу?
              <br>Дахин сэргээх боломжгүйг анхаарна уу.
            </v-card-text>
    
            <v-divider></v-divider>
    
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary darken-1"
                text
                @click="closeDelete"
              >
                Үгүй
              </v-btn>
              <v-btn
                color="primary darken-1"
                text
                @click="deleteItemConfirm"
              >
                Тийм
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

      </v-toolbar>
    </template>

    <template v-slot:header="{ header }">
      <tr class="grey lighten-3">
        <th style="border-right: 1px solid #c8c8c8">
          <v-icon small>mdi-filter-variant</v-icon>
				</th>
        <th style="border-right: 1px solid #c8c8c8" v-for="header in headers" :key="header.text">
          <div v-if="filters.hasOwnProperty(header.value)">
            <v-autocomplete
              flat
              hide-details
              multiple
              small-chips
              dense
              clearable
              :items="columnValueList(header.value)"
              v-model="filters[header.value]"
            >
              <template v-slot:selection="{ item, index }">
                <v-chip small v-if="index < 5">
                  <span> 
                    {{ item }} 
                  </span>
                </v-chip>
                <span v-if="index === 5" class="grey--text caption" > 
                  (+{{ filters[header.value].length - 5 }} others) 
                </span>
              </template>
            </v-autocomplete>
          </div>
          <div v-if="!filters.hasOwnProperty(header.value)">
            <v-icon small>mdi-filter-variant</v-icon>
          </div>
        </th>
      </tr>
    </template>
 
    <template v-slot:item.actions="{ item }">
      <v-icon
        small
        class="mr-2"
        v-if="!readonly_check"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
      <!-- <v-icon
        small
        @click="deleteItem(item)"
      >
        mdi-delete
      </v-icon> -->
    </template>
    <template v-slot:item.no = "{ index }">
      {{ index + 1 }}
    </template>

    <template v-slot:item.amnt="{ item }">
      <span>{{formatPrice(item.amnt)}}</span>
    </template>

    <template v-slot:no-data>
    </template>
  </v-data-table>
</div>
</template>

<script>
  import axios from 'axios';
  
  let bgColorCode = "orange lighten-5";

  export default {
    data: () => ({
      dialog: false,
      dialogDelete: false,
      dialogUpdateAmnt: false,
      amntYear1: '',
      amntYear2: '',
      search: '',
      selected: [],
      loadTable: true,
      headers: [
        {
          text: '№',
          align: 'center',
          sortable: false,
          value: 'no',
          divider: true, 
          class: bgColorCode,
          width: '1%'
        },
        { text: 'Регистрийн дугаар', value: 'reg_id', divider: true, class: bgColorCode, width: '180px' },
        { text: 'Нэр', value: 'name', divider: true, class: bgColorCode },
        { text: 'Утас', value: 'phone', divider: true, class: bgColorCode },
        { text: 'Банкны данс', value: 'bank_acct', divider: true, class: bgColorCode },
        { text: 'Эхний үлдэгдэл', value: 'amnt', divider: true, class: bgColorCode, align: 'right' },
        { text: 'Үйлдэл', value: 'actions', sortable: false, divider: false, class: bgColorCode, align: 'center', width: '1%' },
      ],
      filters: {
        // no: [],
        reg_id: [],
				name: [],
				phone: [],
				bank_acct: [],
        // amnt: [],  
			},
      gridRows: [],
      editedIndex: -1,
      editedItem: {
        id: '',
        reg_id: '',
        name: '',
        phone: '',
        bank_acct: '',
        amnt: '',
      },
      defaultItem: {
        id: '',
        reg_id: '',
        name: '',
        phone: '',
        bank_acct: '',
        amnt: '',
      },
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Шинээр бүртгэх' : 'Засварлах'
      },
      filteredGridRows() {
				return this.gridRows.filter((d) => {
					return Object.keys(this.filters).every((f) => {
						return this.filters[f].length < 1 || this.filters[f].includes(d[f]);
					});
				});
			},
      readonly_check() {
         return sessionStorage.getItem('readonly_st') === 'true';
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
      dialogUpdateAmnt (val) {
        val || this.closeUpdateAmnt()
      },
    },

    created () {
      this.initialize()
    },

    methods: {
      initialize () {
        let user_org = JSON.parse(sessionStorage.getItem('user_org'))
        let user_dt = JSON.parse(sessionStorage.getItem('user_dt')).dt
        const params = {
          org_id: { toJSON: () => user_org.id },
          s_dt: user_dt[0],
          f_dt: user_dt[1]
        };
        axios.get('customer/org_id', { params })
          .then(response => {
            this.gridRows = response.data;
            this.loadTable = false;
            // console.log(JSON.stringify(this.gridRows));
          })
          .catch(error => {
            console.log(error)
          }
        )
      },

      formatPrice(value) {
        if(!value) return;
        let val = value //(value/1).toFixed(2).replace('. ', ',')
        return Number(val).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      },

      required(value) {
        if (value instanceof Array && value.length == 0) {
          return 'Заавал утга оруулна уу.';
        }
        return !!value || 'Заавал утга оруулна уу.';
      },

      columnValueList(val) {
				return this.gridRows.map((d) => d[val]);
			},

      editItem (item) {
        this.editedIndex = this.gridRows.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        if (this.selected.length == 0) {
          alert('Устгах мэдээллийг сонгоно уу.')
          return
        }
        // console.log(JSON.stringify(this.selected))
        this.editedIndex = this.gridRows.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
        let ids = [];
        this.selected.forEach(function (e) {
            ids.push(e.id);
        });

        axios.delete('customer/' + ids)
          .then(response => {
            // sCount ++;
            alert('Сонгогдсон мэдээллийг амжилттай устгалаа.');
            this.reloadMain()
            this.closeDelete()
            // console.log(JSON.stringify(response.data));
          })
          .catch(error => {
            alert('Сонгогдсон мэдээллийг устгахад алдаа гарлаа.');
            console.log(error)
          })

      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
        if (!this.$refs.form.validate()) {
          return;
        }
        this.editedItem.org_id = JSON.parse(sessionStorage.getItem('user_org')).id;
        if (this.editedIndex > -1) {
          axios.put('customer/' + this.gridRows[this.editedIndex].id, this.editedItem)
            .then(response => {
              alert('Харилцагч байгууллагын мэдээлэл амжилттай засварлагдлаа.');
              this.reloadMain();
              // console.log(JSON.stringify(response.data));
            })
            .catch(error => {
              console.log(error)
            })
          // Object.assign(this.gridRows[this.editedIndex], this.editedItem)
        } else {
          axios.post('customer/store', this.editedItem)
            .then(response => {
              alert('Харилцагч байгууллагын мэдээлэл амжилттай бүртгэгдлээ.');
              this.reloadMain();
              // console.log(JSON.stringify(response.data));
            })
            .catch(error => {
              console.log(error)
            })
          //this.gridRows.push(this.editedItem)
        }
        this.close()
      },
      
      reloadMain() {
        this.initialize();
      },
      
      closeUpdateAmnt () {
        this.dialogUpdateAmnt = false
      },

      updateAmnt () {
        var date_range = JSON.parse(sessionStorage.getItem('user_dt')).dt
        this.amntYear1 = Number(date_range[0].substr(0, 4))
        this.amntYear2 = this.amntYear1 + 1
        this.dialogUpdateAmnt = true
      },

      updateAmntConfirm () {
        let dts = [];
        var year = this.amntYear2;
        var org_id = JSON.parse(sessionStorage.getItem('user_org')).id
        this.filteredGridRows.forEach(function (e) {
            dts.push({
              year: year,
              org_id: org_id,
              cust_id: e.id,
              amnt: e.l_amnt
            });
        });

        axios.post('custamnt/store', dts)
          .then(response => {
            alert('Мэдээллийг амжилттай шинэчлэлээ.');
            this.closeUpdateAmnt()
          })
          .catch(error => {
            alert('Мэдээллийг шинэчлэхэд алдаа гарлаа.');
            console.log(error)
          })
      },

    },
  }
</script>