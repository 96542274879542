<template>
   <v-app id="inspire" align='center'>
      <v-main>
         <v-container fluid fill-height>
            <v-layout align-center justify-center>
               <v-flex xs12 sm8 md4>
                  <v-alert dense v-if="show" type="error" >
                     Email хаяг/Нууц үг буруу байна!
                  </v-alert>
                  <v-alert dense v-if="show2" type="error" >
                     Таны эрхийн хугацаа дууссан байна!<br>Сунгалт хийснээр үргэлжлүүлэн ашиглах боломжтой.
                  </v-alert>
                  <v-card class="elevation-12">
                     <v-toolbar dark color="green darken-2">
                        <v-toolbar-title>Системд нэвтрэх хэсэг</v-toolbar-title>
                     </v-toolbar>
                     <v-card-text>
                        <v-form ref="form">
                           <v-text-field
                              prepend-icon="mdi-account"
                              v-model="email"
                              name="email"
                              label="Email хаяг"
                              :rules="[required]"
                              type="text"
                           ></v-text-field>
                           <v-text-field
                              prepend-icon="mdi-lock"
                              v-model="password"
                              name="password"
                              label="Нууц үг"
                              :rules="[required]"
                              type="password"
                           ></v-text-field>
                        </v-form>
                     </v-card-text>
                     <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn :loading="loading" color="green lighten-2" @click="login">Нэвтрэх</v-btn>
                     </v-card-actions>
                  </v-card>

                  <br/>
                  <div>
                     Шинээр хэрэглэгч болох уу?
                     <v-btn color="blue lighten-4" to='/register'>Хүсэлт илгээх</v-btn>
                  </div>

                  <div><br>
                  <v-bottom-navigation>
                     <v-card-text>
                     <i class="mdi mdi-cellphone" aria-hidden="true"></i>
                     Холбоо барих : 8810-3674
                     </v-card-text>
                     <v-spacer></v-spacer>
                     <v-btn
                        href="https://www.facebook.com/excelfinance.mn"
                        target="_blank"
                     >
                        FB хуудас<i class="mdi mdi-facebook" aria-hidden="true"></i>
                     </v-btn>
                  </v-bottom-navigation>
                  </div>

               </v-flex>
            </v-layout>
         </v-container>
      </v-main>
      <v-footer id='app'>
         <v-flex class='text-xs-center'>© 2021. All rights reserved. Excel Finance</v-flex>
      </v-footer>
   </v-app>
</template>

<script>
export default {
   // name: 'Login',
   // props: {
   //    source: String,
   // },
   data (){
      return {
        email: '',
        password: '',
        status: null,
        loading: false,
      }
   },
   // created () {
   //    alert(this.$store.state.status)
   //    if (this.$store.state.status == 'success') {
   //       this.$router.push('/')
   //    }
   //  },
   computed: {
      show() {
         return this.status === 401;
      },
      show2() {
         return this.status === 402;
      }
   },
   methods: {
      required(value) {
        if (value instanceof Array && value.length == 0) {
          return 'Заавал утга оруулна уу.';
        }
        return !!value || 'Заавал утга оруулна уу.';
      },

      login (){
         if (!this.$refs.form.validate()) {
          return;
         }
         this.loading = true;
         let email = this.email 
         let password = this.password
         this.$store.dispatch('login', { email, password })
            .then(() => {
               this.loading = false;
               this.$router.push('/')
            })
            .catch(err => {
               this.loading = false;
               this.status = err.response.status;
               setTimeout(() => {
                  this.status = null;
                  // console.log(this.show)
               }, 5000)
               // this.status = 401;
               console.log(err)
            })
      }
   }
};
</script>

<style></style>
