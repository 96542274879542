import Vue from 'vue'
import Router from 'vue-router'
import store from '../store/index.js'
import Home from '../views/Home.vue'
import Login from '../components/Login.vue'
import Secure from '../components/Secure.vue'
import Register from '../components/Register.vue'
import Customer from '../views/settings/Customer.vue'
import Help from '../views/help/Help.vue'

Vue.use(Router)
 
let router = new Router({
  mode: 'hash',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/login',
      name: 'login',
      component: Login
    },
    {
      path: '/register',
      name: 'register',
      component: Register
    },
    {
      path: '/customer',
      name: 'Customer',
      component: Customer,
      meta: { 
        requiresAuth: true
      }
    },
    {
      path: '/help',
      name: 'Help',
      component: Help,
      meta: { 
        requiresAuth: true
      }
    },
    {
      path: '/tabs',
      name: 'Tabs',
      component: () => import(/* webpackChunkName: "tabs" */ '../views/Tabs.vue'),
      children: [
        {
          path: "balance",
          component:  () => import(/* webpackChunkName: "tabs" */ '../views/tabs/Balance.vue')
        },
        {
          path: "journal",
          component:  () => import(/* webpackChunkName: "tabs" */ '../views/tabs/Journal.vue')
        },
        {
          path: "statement",
          component:  () => import(/* webpackChunkName: "tabs" */ '../views/tabs/Statements.vue')
        },
        {
          path: "receipt",
          component:  () => import(/* webpackChunkName: "tabs" */ '../views/tabs/Receipt.vue')
        },
        {
          path: "asset",
          component:  () => import(/* webpackChunkName: "tabs" */ '../views/tabs/Assets.vue')
        }
      ],
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/rprttabs',
      name: 'RprtTabs',
      component: () => import(/* webpackChunkName: "tabs" */ '../views/RprtTabs.vue'),
      children: [
        {
          path: "finance",
          component:  () => import(/* webpackChunkName: "tabs" */ '../views/rprttabs/Finance.vue')
        },
        {
          path: "income",
          component:  () => import(/* webpackChunkName: "tabs" */ '../views/rprttabs/Income.vue')
        },
        {
          path: "assets",
          component:  () => import(/* webpackChunkName: "tabs" */ '../views/rprttabs/Assets.vue')
        },
        {
          path: "money",
          component:  () => import(/* webpackChunkName: "tabs" */ '../views/rprttabs/Money.vue')
        }
      ],
      meta: { 
        requiresAuth: true
      }
    },
    {
      path: '/saltabs',
      name: 'SalTabs',
      component: () => import(/* webpackChunkName: "tabs" */ '../views/SalTabs.vue'),
      children: [
        {
          path: "employer",
          component:  () => import(/* webpackChunkName: "tabs" */ '../views/saltabs/Employer.vue')
        },
        {
          path: "salary",
          component:  () => import(/* webpackChunkName: "tabs" */ '../views/saltabs/Salary.vue')
        }
      ],
      meta: { 
        requiresAuth: true
      }
    },
    {
      path: '/settings',
      name: 'StgsTabs',
      component: () => import(/* webpackChunkName: "tabs" */ '../views/StgsTabs.vue'),
      children: [
        {
          path: "orgs",
          component:  () => import(/* webpackChunkName: "tabs" */ '../views/settings/Orgs.vue')
        },
        {
          path: "accounts",
          component:  () => import(/* webpackChunkName: "tabs" */ '../views/settings/Accounts.vue')
        },
        {
          path: "misc",
          component:  () => import(/* webpackChunkName: "tabs" */ '../views/settings/Misc.vue')
        }
      ],
      meta: { 
        requiresAuth: true
      }
    },
  
    {
      path: '/secure',
      name: 'secure',
      component: Secure,
      meta: { 
        requiresAuth: true
      }
    },
  ]
})

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next()
      var refresh = localStorage.getItem('refresh');
      if (refresh!==null){
          window.location.reload();
          localStorage.removeItem('refresh')
      }
      return
    }
    next('/login') 
  } else if(store.getters.isLoggedIn) {
    next('/')
  } else {
    next() 
  }
});

const DEFAULT_TITLE = 'Excel Finance';
router.afterEach((to, from) => {
    // Use next tick to handle router history correctly
    // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
    Vue.nextTick(() => {
        document.title = to.meta.title || DEFAULT_TITLE;
    });
});

export default router