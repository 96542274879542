import Vue from 'vue'
import App from './Main.vue'
import router from './router/index'
import store from './store/index'
import vuetify from './plugins/vuetify';
import Axios from 'axios';
import VueJsonCsv from 'vue-json-csv';

Vue.prototype.$http = Axios;

Vue.component('VueJsonCsv',VueJsonCsv) 

const token = sessionStorage.getItem('token')
if (token) {
  Vue.prototype.$http.defaults.headers.common['Authorization'] = 'Bearer ' + token
}

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
