<template>
<div style="background-color:grey">
  <v-app id="inspire">
    <v-main v-if="isLoggedIn">
      <v-navigation-drawer 
        v-model="drawer"
        app
        color="grey lighten-3"
        >
          <v-dialog
            v-model="orgDialog"
            max-width="500px"
            persistent
            :retain-focus="false"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-col
                cols="auto"
                sm="12"
              >
              <v-text-field
                v-model="orgName"
                hint="Аж ахуйн нэгжийн нэр"
                persistent-hint
                outlined
                dense
                readonly
                background-color="grey lighten-3"
                append-icon="mdi-arrow-bottom-right-thin-circle-outline"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
              </v-col>
            </template>
            <v-card>
              <v-card-title class="headline green lighten-2" primary-title>
                Аж ахуйн нэгжийг сонгоно уу.
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-form ref="form">
                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <v-combobox
                        v-model="selectedOrg"
                        hint="Аж ахуйн нэгжийн нэр"
                        persistent-hint
                        :rules="[required]"
                        :items="orgs"
                        item-text="name"
                        item-value="id"
                        outlined
                        dense
                      ></v-combobox>
                    </v-col>
                  </v-row>
                  </v-form>
                </v-container>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="closeOrgDialog"
                >
                  Буцах
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="saveOrgDialog"
                >
                  Сонгох
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

        <v-divider></v-divider>

        <v-list
          dense
          nav
        >
          <v-list-item
            align='left'
            v-for="item in items"
            :key="item.title"
            :to="item.to"
            link
          >
            <v-list-item-icon>
              <v-icon class="mx-1">{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link @click="logout">
              <v-list-item-action>
                  <v-icon class="mx-1">mdi-power</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                  <v-list-item-title>Log Out</v-list-item-title>
              </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-bottom-navigation>
          <v-card-text>
            <i class="mdi mdi-cellphone" aria-hidden="true"></i>
            Утас : 8810-3674
          </v-card-text>
          <v-spacer></v-spacer>
          <v-btn
            href="https://www.facebook.com/excelfinance.mn"
            target="_blank"
          >
            FB хуудас<i class="mdi mdi-facebook" aria-hidden="true"></i>
          </v-btn>
        </v-bottom-navigation>

        <v-alert dense v-if="readonly_check" type="error" >
            Таны эрхийн хугацаа дууссан байна!<br>6 сарын хугацаанд зөвхөн харах эрхтэй.
        </v-alert>
        <v-col cols="auto">
          <v-text-field
            v-model="validDtText"
            label="Эрхийн хүчинтэй огноо:"
            filled
            rounded
            readonly
            background-color="red lighten-4"
            prepend-inner-icon="mdi-bell-circle-outline"
          ></v-text-field>
        </v-col>

      </v-navigation-drawer>

      <v-app-bar
        app
        color="green accent-5"
        dark
        prominent
        height="90"
      >
        <template v-slot:img="{ props }">
          <v-img
            v-bind="props"
            gradient="to top right, rgba(19,84,122,.5), rgba(128,208,199,.8)"
          ></v-img>
        </template>

        <v-app-bar-nav-icon  @click="drawer = !drawer"></v-app-bar-nav-icon>
          
        <v-toolbar-title>Excel Finance</v-toolbar-title>
        
        <v-spacer></v-spacer>

        <v-dialog
          v-model="dtDialog"
          max-width="500px"
          persistent
          :retain-focus="false"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-col
              cols="auto"
              sm="3"
            >
              <v-text-field
                v-model="dateRangeText"
                label="Тайлант үе :"
                filled
                rounded
                readonly
                background-color="grey"
                prepend-inner-icon="mdi-calendar-cursor"
                hide-details
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </v-col>
          </template>
          <v-card>
            <v-card-title class="headline green lighten-2" primary-title>
              Тайлант хугацааг сонгоно уу.
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-form ref="formDt">
                <v-row>
                  <v-col
                    cols="auto"
                    sm="6"
                  >
                    <v-menu
                      v-model="sDtMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="date_range[0]"
                          hint="Эхлэх огноо"
                          persistent-hint
                          :rules="[required]"
                          outlined
                          dense
                          readonly
                          background-color="grey lighten-3"
                          append-icon="mdi-calendar-cursor"
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="date_range[0]"
                        @input="sDtMenu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col
                    cols="auto"
                    sm="6"
                  >
                    <v-menu
                      v-model="fDtMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="date_range[1]"
                          hint="Дуусах огноо"
                          persistent-hint
                          :rules="[required]"
                          outlined
                          dense
                          readonly
                          background-color="grey lighten-3"
                          append-icon="mdi-calendar-cursor"
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="date_range[1]"
                        @input="fDtMenu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                </v-form>
              </v-container>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="closeDateRangeDialog"
              >
                Буцах
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="saveDateRangeDialog"
              >
                Сонгох
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-btn icon>
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </v-app-bar>
      <v-card height="100%" color="#e4e4e4">
        <router-view/>
      </v-card>

    </v-main>

    <v-main v-else-if="!isLoggedIn">
      <router-view/>
    </v-main>

  </v-app>
  </div>
</template>

<script>
  import axios from 'axios';
  import moment from 'moment';

  export default {
    data (){
      return {
        orgDialog: false,
        dtDialog: false,
        drawer: null,
        date_range: [moment().startOf('year').format('YYYY-MM-DD'), moment().endOf('year').format('YYYY-MM-DD')],
        // date_range: [new Date(new Date().getFullYear(),0,1).toISOString().substring(0,10), new Date(new Date().getFullYear(),11,31).toISOString().substring(0,10)],
        sDtMenu: false,
        fDtMenu: false,
        items: [
            { title: 'Журнал', icon: 'mdi-animation-play-outline', to: '/tabs/journal'},
            { title: 'Харилцагч', icon: 'mdi-face-agent', to: '/customer'},
            { title: 'Тохиргоо', icon: 'mdi-cogs', to: '/settings/orgs'},
            { title: 'Тусламж', icon: 'mdi-frequently-asked-questions', to: '/help'}
        ],
        selectedOrg: null, //this.$store.state.org,
        orgName: null, //this.$store.state.org.name,
        orgs: [],
        rules: {
          required: value => !!value || 'Required.',
          counter: value => value.length <= 20 || 'Max 20 characters',
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'Invalid e-mail.'
          },
          number: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'Invalid number.'
          },
        },
      }
    },

    computed: {
      isLoggedIn () {
        return this.$store.getters.isLoggedIn
      },
      dateRangeText () {
        return this.date_range.join(' ~ ')
      },
      validDtText () {
        return sessionStorage.getItem('valid_dt');
      },
      readonly_check() {
         return sessionStorage.getItem('readonly_st') === 'true';
      }
    },

    watch: {
      orgDialog (val) {
        val || this.closeOrgDialog()
      },
      dtDialog (val) {
        val || this.closeDateRangeDialog()
      },
    },

    created () {
      this.$http.interceptors.response.use(undefined, function (err) {
        return new Promise(function (resolve, reject) {
          if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
            this.$store.dispatch(logout)
          }
          throw err;
        });
      });
      // if (this.isLoggedIn) {
        this.checkDateRange()
        this.checkOrgSelected()
        // this.initialize()
      // }
    },

    methods: {
      initialize () {
        // alert(window.location.hash)
        // if(!window.location.hash) {
        //   window.location = window.location + '#loaded';
        //   window.location.reload();
        // }
        // let userId = JSON.parse(sessionStorage.getItem('user')).id
        // axios.get('org/by_user/' + userId)
        //   .then(response => {
        //     this.orgs = response.data;
        //   })
        //   .catch(error => {
        //     console.log(error)
        //   })
        // this.checkOrgSelected()
        // this.checkDateRange()
      },
      
      required(value) {
        if (value instanceof Array && value.length == 0) {
          return 'Заавал утга оруулна уу.';
        }
        return !!value || 'Заавал утга оруулна уу.';
      },

      logout () {
        this.$store.dispatch('logout')
        .then(() => {
          this.$router.push('/login')
        })
      },
      checkOrgSelected () {
        let cond = true
        if (sessionStorage.hasOwnProperty('user_org')) {
          this.selectedOrg = JSON.parse(sessionStorage.getItem('user_org'))
          this.orgName = this.selectedOrg.name
          cond = false
        }
        let userId = sessionStorage.hasOwnProperty('user') ? JSON.parse(sessionStorage.getItem('user')).id : null
        if (userId !== null) {
          axios.get('org/by_user/' + userId)
            .then(response => {
              this.orgs = response.data;
              this.orgDialog = cond
            })
            .catch(error => {
              console.log(error)
            })
        }
        this.orgDialog = cond
      },
      checkDateRange () {
        // let cond = true
        if (sessionStorage.hasOwnProperty('user_dt')) {
          this.date_range = JSON.parse(sessionStorage.getItem('user_dt')).dt
          // cond = false
        }
        // this.dtDialog = cond
      },
      closeOrgDialog () {
        this.orgDialog = false
      },
      closeDateRangeDialog () {
        this.dtDialog = false
      },
      saveOrgDialog () {
        if (!this.$refs.form.validate()) {
          return;
        }
        let id = this.selectedOrg.id
        let name  = this.selectedOrg.name
        this.$store.dispatch('changeOrg', { id, name })
        this.orgName = name
        this.orgDialog = false
        !this.dtDialog ? window.location.reload() : true
      },
      saveDateRangeDialog () {
        if (!this.$refs.formDt.validate()) {
          return;
        }
        let dt = this.date_range
        this.$store.dispatch('changeDt', { dt })
        this.dtDialog = false
        !this.orgDialog ? window.location.reload() : true
      },
    }
  }
</script>