import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import JsonExcel from 'vue-json-excel'
import moment from 'moment'

Vue.component("downloadExcel", JsonExcel);

Vue.use(Vuex)
// axios.defaults.baseURL = 'http://localhost/efback/public/';
// for prod settings
// axios.defaults.baseURL = 'https://restapi.excelfinance.mn/';
axios.defaults.baseURL = 'http://103.153.141.100:8081/';


export default new Vuex.Store({
	state: {
  		status: '',
  		token: sessionStorage.getItem('token') || '',
  		user : {},
		org: {},
		dt: {}
	},
	mutations: {
		auth_request(state){
	    	state.status = 'loading'
	  	},
	  	auth_success(state, token, user){
		    state.status = 'success'
		    state.token = token
		    state.user = user
	  	},
	  	auth_error(state){
	    	state.status = 'error'
	  	},
	  	logout(state){
	    	state.status = ''
	    	state.token = ''
			state.user = {}
			state.org = {}
			state.dt = {}
	  	},
      	set_org(state, org) {
			state.org = org
		},
		set_dt(state, dt) {
			state.dt = dt
		},
	},
	actions: {
	  	login({commit}, user){
	        return new Promise((resolve, reject) => {
	            commit('auth_request')
	            axios({url: 'login', data: user, method: 'POST' })
	            .then(resp => {
					console.log(resp)
	                const token = resp.data.access_token
	                const user = resp.data.user
	                const payment = resp.data.payment
	                sessionStorage.setItem('token', token)
					sessionStorage.setItem('user', JSON.stringify(user))
	                sessionStorage.setItem('org_limit', JSON.stringify(payment.org_limit))
					sessionStorage.setItem('valid_dt', payment.valid_dt)
					sessionStorage.setItem('readonly_st', resp.data.readonly)
					// Set default date range
					var cdt = new Date();
					let dt_range = [moment().startOf('year').format('YYYY-MM-DD'), moment().endOf('year').format('YYYY-MM-DD')];
					// let dt_range = [new Date(new Date().getFullYear(), 0, 1).toISOString().substring(0,10), new Date(new Date().getFullYear(), 11, 31).toISOString().substring(0,10)];
					// let dt_range = [new Date(cdt.getFullYear(),0,2).toISOString().substring(0,10), new Date(cdt.getFullYear(),11,32).toISOString().substring(0,10)]
					let dt = {'dt': dt_range}
					sessionStorage.setItem('user_dt', JSON.stringify(dt))
					commit('set_dt', JSON.stringify(dt))
					localStorage.setItem('refresh', '1')
	                // Add the following line:
	                axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
	                commit('auth_success', token, user)
	                resolve(resp)
	            })
	            .catch(err => {
	                commit('auth_error')
	             	sessionStorage.clear()
					// sessionStorage.removeItem('token')
	                // sessionStorage.removeItem('user')
	                // sessionStorage.removeItem('org_limit')
	                reject(err)
	            })
	        })
	    },
	    register({commit}, user){
	    	return new Promise((resolve, reject) => {
	            commit('auth_request')
	            axios({url: 'register', data: user, method: 'POST' })
	            .then(resp => {
					console.log(resp)
	                const token = resp.data.access_token
	                const user = resp.data.user
	                sessionStorage.setItem('token', token)
	                // Add the following line:
	                axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
	                commit('auth_success', token, user)
	                resolve(resp)
	            })
	            .catch(err => {
	                commit('auth_error', err)
	                sessionStorage.removeItem('token')
	                reject(err)
	            })
	        })
	    },
	  	logout({commit}){
		    return new Promise((resolve, reject) => {
		      	commit('logout')
				sessionStorage.clear()
		      	// sessionStorage.removeItem('token')
				// sessionStorage.removeItem('user')
				// sessionStorage.removeItem('org_limit')
				// sessionStorage.removeItem('user_org')
				// sessionStorage.removeItem('user_dt')
		      	delete axios.defaults.headers.common['Authorization']
		      	resolve()
		    })
	  	},
		changeOrg({commit}, org){
			return new Promise((resolve, reject) => {
				sessionStorage.setItem('user_org', JSON.stringify(org))
				commit('set_org', org)
				resolve()
			})
	    },
		changeDt({commit}, dt){
			return new Promise((resolve, reject) => {
				sessionStorage.setItem('user_dt', JSON.stringify(dt))
				commit('set_dt', dt)
				resolve()
			})
	    },
	},
	getters : {
	  isLoggedIn: state => !!state.token,
	  authStatus: state => state.status,
	  orgInfo: state => state.org,
	  dtInfo: state => state.dt,
	  uInfo: state => state.user,
	}
})